<template>
	<v-sheet style="overflow-x: hidden" outlined rounded>
		<v-form ref="form" @submit.prevent="handleAddViability">
			<v-row justify="center" no-gutters>
				<v-col style="margin: 40px; min-width: 300px; max-width: 400px">
					<v-autocomplete
						outlined
						v-model="creditManager"
						item-text="name"
						item-value="_id"
						label="Gestora de Crédito"
						:items="managers"
					/>
					<v-autocomplete
						outlined
						v-model="state"
						:items="config.viabilityStates"
						item-text="name"
						item-value="key"
						label="Estado"
					/>
					<div style="display: flex">
						<v-combobox
							outlined
							v-model="consultant"
							item-text="name"
							label="Consultor"
							:items="consultants"
							@change="
								newVal => {
									this.isExternalConsultant = !newVal?._id;
									this.consultantTeam = newVal?.team || null;
									this.consultantCommercialDirector = newVal?.manager || null;
								}
							"
						/>
						<v-checkbox
							v-model="isExternalConsultant"
							hint="Externo"
							persistent-hint
							style="top: -5px; left: 10px; position: relative; min-width: 45px"
							@change="() => (this.consultant = '')"
						/>
					</div>
					<div style="margin-bottom: 10px">Compradores</div>
					<v-expansion-panels popout multiple style="margin-bottom: 10px">
						<v-expansion-panel v-for="(person, index) in buyers" :key="person.key" style="border: 1px solid #888">
							<v-expansion-panel-header>
								<div v-if="index !== 0" style="display: flex">
									{{ person.name || `Comprador ${index + 1}` }}
									<v-btn
										btn
										x-small
										color="error"
										@click="() => deletePerson(person.key)"
										style="margin: 0px 10px"
									>
										<v-icon small>mdi-delete</v-icon>
									</v-btn>
								</div>
								<div v-else>
									{{ person.name || `Comprador ${index + 1}` }}
								</div>
							</v-expansion-panel-header>
							<v-expansion-panel-content eager>
								<div style="display: flex; flex-direction: column; gap: 10px">
									<v-text-field outlined v-model="person.name" label="Nome" hide-details />
									<v-text-field
										outlined
										v-model="person.phone"
										label="Nº de telefone"
										hide-details
										:rules="[validPhone]"
									/>
									<v-text-field outlined v-model="person.email" label="Email" hide-details :rules="[validEmail]" />
									<v-text-field
										outlined
										v-model="person.nif"
										label="NIF"
										type="number"
										hide-details
										:rules="[validNif]"
									/>
								</div>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
					<center style="margin-bottom: 20px">
						<v-btn fab small color="primary" @click="() => addPerson()">
							<v-icon>mdi-plus</v-icon>
						</v-btn>
					</center>
					<v-checkbox v-model="cc" label="CC" />
					<v-checkbox v-model="paySlips" label="Recibos de Vencimento" style="padding: 0px; margin: 0px" />
					<v-checkbox v-model="bankStatements" label="Extratos Bancários" style="padding: 0px; margin: 0px" />
					<v-checkbox v-model="irs" label="IRS" style="padding: 0px; margin: 0px" />
					<v-checkbox v-model="settlementNote" label="Nota de Liquidação" style="padding: 0px; margin: 0px" />
					<v-checkbox
						v-model="declarationOfEffectiveness"
						label="Declaração de Efetividade"
						style="padding: 0px; margin: 0px"
					/>
					<v-checkbox v-model="rgpd" label="RGPD" style="padding: 0px; margin: 0px" />
					<v-checkbox
						v-model="responsabilitiesMap"
						label="Mapa de Responsabilidades"
						style="padding: 0px; margin: 0px"
					/>
					<div
						v-for="(extraBoolean, index) in extraBooleans"
						:key="index"
						style="display: flex; align-items: center"
					>
						<v-checkbox
							v-model="extraBoolean.value"
							:label="extraBoolean.editable ? '' : extraBoolean.name"
							:style="`padding: 0px; margin: 0px${extraBoolean.editable ? '' : '; width: 100%'}`"
						/>
						<v-text-field v-if="extraBoolean.editable" outlined dense v-model="extraBoolean.name" label="Nome" />
						<v-btn
							icon
							color="error"
							@click="() => deleteExtraBoolean(index)"
							style="margin-left: 10px; bottom: 12px"
						>
							<v-icon>mdi-delete</v-icon>
						</v-btn>
					</div>
					<center
						v-if="!extraBooleans.length || extraBooleans[extraBooleans.length - 1].name"
						style="margin-bottom: 20px"
					>
						<v-btn fab small color="primary" @click="() => addExtraBoolean()">
							<v-icon>mdi-plus</v-icon>
						</v-btn>
					</center>
					<v-menu :close-on-content-click="false" transition="scale-transition" offset-y>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								outlined
								v-model="date"
								label="Data de Entrada"
								v-bind="attrs"
								v-on="on"
								:rules="[required]"
								readonly
							/>
						</template>
						<v-date-picker v-model="date" color="primary" />
					</v-menu>
					<v-menu :close-on-content-click="false" transition="scale-transition" offset-y>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								outlined
								v-model="scheduledDate"
								label="Data de Agendamento"
								v-bind="attrs"
								v-on="on"
								readonly
								clearable
							/>
						</template>
						<v-date-picker v-model="scheduledDate" color="primary" />
					</v-menu>
					<v-menu :close-on-content-click="false" transition="scale-transition" offset-y>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								outlined
								v-model="giveUpDate"
								label="Data de Desistência"
								v-bind="attrs"
								v-on="on"
								readonly
								clearable
							/>
						</template>
						<v-date-picker v-model="giveUpDate" color="primary" />
					</v-menu>
					<v-combobox
						v-if="giveUpDate"
						outlined
						v-model="giveUpReason"
						item-text="name"
						item-value="key"
						label="Razão da Desistência"
						:items="config.giveUpReasons"
						:rules="[required]"
						hide-details
					/>
				</v-col>
				<v-divider v-if="windowWidth > 1600" vertical />
				<v-col style="margin: 40px; min-width: 1050px; max-width: 1050px">
					<v-simple-table dense>
						<template v-slot:default>
							<thead>
								<tr>
									<th>Banco</th>
									<th>Pedido</th>
									<th>Valor Pedido</th>
									<th>Aprovado</th>
									<th>Valor Aprovado</th>
									<th>Escolhido</th>
									<th>Valor de Aquisição</th>
									<th>Observações</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="request in requests" :key="request.bank">
									<td>{{ config.banks.find(b => b.key === request.bank)?.name }}</td>
									<td><v-checkbox v-model="request.requested" /></td>
									<td>
										<v-text-field
											outlined
											append-icon="mdi-currency-eur"
											v-model="request.requestedCredit"
											type="number"
											hide-details
											:disabled="!request.requested"
										/>
									</td>
									<td>
										<v-checkbox v-model="request.approved" :disabled="!request.requested" />
									</td>
									<td>
										<v-text-field
											outlined
											append-icon="mdi-currency-eur"
											v-model="request.approvedCredit"
											type="number"
											hide-details
											:disabled="!request.approved"
										/>
									</td>
									<td>
										<v-checkbox v-model="request.chosen" :disabled="!request.approved" />
									</td>
									<td>
										<v-text-field
											outlined
											append-icon="mdi-currency-eur"
											v-model="request.chosenCredit"
											type="number"
											hide-details
											:disabled="!request.chosen"
										/>
									</td>
									<td>
										<v-textarea
											outlined
											auto-grow
											row-height="1"
											v-model="request.observations"
											hide-details
											style="margin: 10px 0px"
										/>
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-col>
			</v-row>
			<v-btn v-if="!viability" @click="handleAddViability">Adicionar</v-btn>
			<v-btn v-if="viability" @click="handleEditViability">Atualizar</v-btn>
			<br /><br />
		</v-form>
	</v-sheet>
</template>

<script>
import Vue from "vue";
import dayjs from "dayjs";

import { mapState, mapMutations } from "vuex";

import { getUsers } from "../api/users";
import { addViability, editViability } from "../api/viabilities";

import { formatDate } from "../utils/utils";

export default Vue.extend({
	name: "ViabilityDetail",
	props: ["viability", "onClose"],
	created() {
		this.handleGetUsers();

		this.setViability(this.viability);

		this.windowWidth = window.innerWidth;

		window.addEventListener("resize", () => (this.windowWidth = window.innerWidth));
	},
	watch: {
		viability: function (newVal) {
			this.setViability(newVal);
		},
	},
	computed: mapState(["company", "config", "selectedOffices", "user"]),
	data() {
		const emailRegex =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		return {
			loading: false,

			required: value => !!value || "Obrigatório",
			validEmail: value => !value || (value && emailRegex.test(value)) || "Email inválido",
			validPhone: value =>
				!value || (value && (value.includes("+") ? true : value.length === 9)) || "Telefone inválido",
			validNif: value => !value || (value && value.toString().length === 9) || "NIF inválido",

			consultants: [],
			managers: [],

			date: formatDate(dayjs(), "YYYY-MM-DD"),
			state: "",
			consultant: "",
			isExternalConsultant: true,
			consultantTeam: null,
			consultantCommercialDirector: null,
			creditManager: null,
			buyers: [{ key: 0 }],
			scheduledDate: "",
			giveUpDate: "",
			giveUpReason: "",
			cc: false,
			paySlips: false,
			bankStatements: false,
			irs: false,
			settlementNote: false,
			declarationOfEffectiveness: false,
			rgpd: false,
			responsabilitiesMap: false,
			extraBooleans: [],
			requests: [],

			windowWidth: 0,
		};
	},
	methods: {
		...mapMutations(["openToast"]),
		setViability(newVal) {
			if (newVal) {
				this.state = newVal.state;
				this.consultant = newVal.consultant;
				this.isExternalConsultant = newVal.isExternalConsultant;
				this.consultantTeam = newVal.consultantTeam;
				this.consultantCommercialDirector = newVal.consultantCommercialDirector;
				this.creditManager = newVal.creditManager;
				this.buyers = newVal.buyers;
				this.date = formatDate(newVal.date, "YYYY-MM-DD");
				this.scheduledDate = newVal.scheduledDate && formatDate(newVal.scheduledDate, "YYYY-MM-DD");
				this.giveUpDate = newVal.giveUpDate && formatDate(newVal.giveUpDate, "YYYY-MM-DD");
				this.giveUpReason =
					this.config.giveUpReasons.find(r => r.key === newVal.giveUpReason) || newVal.giveUpReason;
				this.cc = newVal.cc;
				this.paySlips = newVal.paySlips;
				this.bankStatements = newVal.bankStatements;
				this.irs = newVal.irs;
				this.settlementNote = newVal.settlementNote;
				this.declarationOfEffectiveness = newVal.declarationOfEffectiveness;
				this.rgpd = newVal.rgpd;
				this.responsabilitiesMap = newVal.responsabilitiesMap;
				this.extraBooleans = newVal.extraBooleans;
				this.requests = JSON.parse(JSON.stringify(newVal.requests));

				for (const bank of this.config.banks) {
					if (!this.requests.find(b => b.bank === bank.key)) {
						this.requests.push({ bank: bank.key });
					}
				}
			} else {
				this.state = "";
				this.consultant = "";
				this.isExternalConsultant = true;
				this.consultantTeam = null;
				this.consultantCommercialDirector = null;
				this.creditManager = this.user.role === "creditManager" ? this.user._id : null;
				this.buyers = [{ key: 0 }];
				this.date = formatDate(dayjs(), "YYYY-MM-DD");
				this.scheduledDate = "";
				this.giveUpDate = "";
				this.giveUpReason = "";
				this.cc = false;
				this.paySlips = false;
				this.bankStatements = false;
				this.irs = false;
				this.settlementNote = false;
				this.declarationOfEffectiveness = false;
				this.rgpd = false;
				this.responsabilitiesMap = false;
				this.extraBooleans = [];
				this.requests = this.config.banks.map(b => ({ bank: b.key }));
			}
		},
		isFormValid() {
			if (!this.$refs.form.validate()) return false;

			return true;
		},
		async addPerson() {
			this.buyers.push({ key: this.buyers.length + 1 });
		},
		async deletePerson(key) {
			this.buyers = this.buyers.filter(d => d.key !== key);
		},
		async addExtraBoolean() {
			this.extraBooleans.push({ name: "", value: false, editable: true });
		},
		async deleteExtraBoolean(index) {
			this.extraBooleans.splice(index, 1);
		},
		async handleGetUsers() {
			const response = await getUsers(0, 10000, "name", false, { all: true, infoLevel: 2 });

			if (response.status === 200) {
				this.consultants = response.data.users.filter(u =>
					["consultant", "teamLeader", "commercialDirector", "broker", "creditManager"].includes(u.role),
				);
				this.managers = response.data.users.filter(
					u =>
						u.active &&
						u.offices.some(o => this.selectedOffices.includes(o)) &&
						["teamLeader", "creditManager"].includes(u.role),
				);
			}
		},
		async handleAddViability() {
			this.loading = true;
			const {
				state,
				consultant,
				isExternalConsultant,
				consultantTeam,
				consultantCommercialDirector,
				creditManager,
				buyers,
				date,
				scheduledDate,
				giveUpDate,
				giveUpReason,
				cc,
				paySlips,
				bankStatements,
				irs,
				settlementNote,
				declarationOfEffectiveness,
				rgpd,
				responsabilitiesMap,
				extraBooleans,
				requests,
			} = this;

			if (!this.isFormValid()) {
				this.openToast({ message: "Faltam campos obrigatórios", color: "red" });

				return;
			}

			const response = await addViability({
				state,
				consultant: isExternalConsultant ? consultant : consultant._id,
				isExternalConsultant,
				consultantTeam,
				consultantCommercialDirector,
				creditManager,
				buyers,
				date,
				scheduledDate,
				giveUpDate,
				giveUpReason: giveUpReason && giveUpReason.key ? giveUpReason.key : giveUpReason,
				cc,
				paySlips,
				bankStatements,
				irs,
				settlementNote,
				declarationOfEffectiveness,
				rgpd,
				responsabilitiesMap,
				extraBooleans,
				requests,
			});

			if (response.status === 201) {
				this.onClose();

				this.setViability();
			} else if (response.status === 400) {
				this.openToast({ message: "Escolha uma agência", color: "red" });
			}

			this.loading = false;
		},
		async handleEditViability() {
			this.loading = true;
			const {
				state,
				consultant,
				isExternalConsultant,
				consultantTeam,
				consultantCommercialDirector,
				creditManager,
				buyers,
				date,
				scheduledDate,
				giveUpDate,
				giveUpReason,
				cc,
				paySlips,
				bankStatements,
				irs,
				settlementNote,
				declarationOfEffectiveness,
				rgpd,
				responsabilitiesMap,
				extraBooleans,
				requests,
			} = this;

			if (!this.isFormValid()) {
				this.openToast({ message: "Faltam campos obrigatórios", color: "red" });

				return;
			}

			const response = await editViability({
				_id: this.viability._id,
				state,
				consultant: isExternalConsultant ? consultant : consultant._id,
				isExternalConsultant,
				consultantTeam,
				consultantCommercialDirector,
				creditManager,
				buyers,
				date,
				scheduledDate,
				giveUpDate,
				giveUpReason: giveUpReason && giveUpReason.key ? giveUpReason.key : giveUpReason,
				cc,
				paySlips,
				bankStatements,
				irs,
				settlementNote,
				declarationOfEffectiveness,
				rgpd,
				responsabilitiesMap,
				extraBooleans,
				requests,
			});

			if (response.status === 200) {
				this.onClose();
			}

			this.loading = false;
		},
	},
});
</script>
