<template>
	<DataTable
		type="users"
		:featuresMap="{
			add: features.addStaff,
			edit: features.editStaff,
			delete: features.deleteStaff,
			addFiles: true,
		}"
		title="Staff"
		:quickFilters="quickFilters"
		:filtersConfig="filtersConfig"
		:getItems="getUsers"
		:patchItem="patchUser"
		:deleteItem="deleteUser"
		:actions="['comments', 'tasks', 'files', 'changes', 'view', 'edit', 'delete']"
		sortByOverwrite="startDate"
	>
		<template v-slot:detail="{ selectedItem, closeDetail }">
			<StaffForm :staff="selectedItem" :onClose="closeDetail" />
		</template>
	</DataTable>
</template>
<script>
import Vue from "vue";
import { mapState } from "vuex";

import DataTable from "../components/DataTable";
import StaffForm from "../components/StaffForm";

import { getUsers, patchUser, deleteUser } from "../api/users";

export default Vue.extend({
	name: "Staff",
	components: { DataTable, StaffForm },
	computed: mapState(["features"]),
	data() {
		return {
			quickFilters: [
				{
					name: "Consultores",
					value: "consultants",
					icon: "mdi-account-star",
					filters: { role: ["consultant"] },
				},
				{
					name: "Administração",
					value: "admin",
					icon: "mdi-account-key",
					filters: {
						role: [
							"ceo",
							"cfo",
							"coo",
							"broker",
							"recruiter",
							"expansionDirector",
							"hr",
							"processManager",
							"creditManager",
							"quality",
							"marketing",
							"assistant",
							"lawyer",
						],
					},
				},
			],

			filtersConfig: [
				{ type: "autocomplete", label: "Cargos", value: "role", items: "config.roles", itemValue: "key" },
				{ type: "autocomplete", label: "Responsáveis", value: "manager", items: "managers" },
				{ type: "autocomplete", label: "Equipas", value: "team", items: "config.teams", itemValue: "key" },
				{ type: "date", label: "Data de Entrada", value: "startDateRange" },
				{
					type: "buttonToggle",
					value: "experient",
					options: [
						{ value: true, label: "Experiente" },
						{ value: "null", label: "Tudo" },
						{ value: false, label: "Não Experiente" },
					],
				},
				{
					type: "buttonToggle",
					value: "gender",
					options: [
						{ value: "male", label: "Masculino" },
						{ value: "null", label: "Tudo" },
						{ value: "female", label: "Feminino" },
					],
				},
			],
		};
	},
	methods: { getUsers, patchUser, deleteUser },
});
</script>
